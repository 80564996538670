/*eslint-disable*/
import React, { useState } from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
import { openPopupWidget } from "react-calendly"
// react components used to create a google map
import { LocalBusinessJsonLd } from "gatsby-plugin-next-seo"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Parallax from "../components/Parallax/ParallaxContact.jsx"

import PinDrop from "@material-ui/icons/PinDrop"


// core components
import Header from "../components/Header/Header.jsx"
import HeaderLinks from "../components/Header/HeaderLinks.jsx"

import Grid from "@material-ui/core/Grid"


import Button from "@material-ui/core/Button"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"
import Footer from "../components/Footer/Footer.jsx"

import styles from "../jss/contact-us.js"
const useStyles = makeStyles(styles)

export class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null, MESSAGEs: "" }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email, {
      MESSAGE: this.state.MESSAGE,
    })
      .then(data => {
        this.setState({ result: data })
        navigate("/thank-you")
      })
      .catch(() => {})
  }
  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  handleMessage = event => {
    this.setState({ MESSAGE: event.target.value })
  }
  render() {
    const CustomButton = ({ url, prefill, pageSettings, utm }) => {
      const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })

      return (
        <Button
          style={{
            backgroundColor: "#213f94",
            color: "white",
            marginTop: "10px",
            height: "35px",
            fontSize: "15px",
            position: "relative",
            left: "5px",
            top: "-5px"
          }}
          variant="contained"
          onClick={onClick}
        >
          Free Consultation
        </Button>
      )
    }
    const classes = this.props.classes
    return (
      <div>
        <LogoJsonLd
          logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
          url="https://atlasagency.ca"
        />
        <GatsbySeo
          title=" Atlas Agency | Contact Us | Website Design Toronto"
          description="We are available to help you with your next website design. Contact us to discuss your project in greater detail."
          canonical="https://atlasagency.ca/contact-us-web-design-toronto/"
          openGraph={{
            type: "website",
            locale: "en_IE",
            description:
              "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
            url: "https://atlasagency.ca",
            site_name: "Atlas Agency | Web Design Toronto",
            images: [
              {
                url:
                  "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
                width: 800,
                height: 450,
                alt: "Web Design Toronto",
              },
            ],
          }}
          twitter={{
            cardType: "summary_large_image",
            site: "@AtlasAgencyCA",
          }}
        />
        <LocalBusinessJsonLd
          type="ProfessionalService"
          id="https://atlasagency.ca/"
          name="AtlasAgency"
          description="Experienced team of Toronto web designers and Toronto SEO experts. We help you design and optimize your company website to rank higher on Google searches."
          url="https://atlasagency.ca"
          telephone="+6473710263"
          address={{
            streetAddress: "895 Don Mills Road",
            addressLocality: "Toronto",
            addressRegion: "ON",
            postalCode: "M3C1W3",
            addressCountry: "CA",
          }}
          priceRange="$$$"
          geo={{
            latitude: "43.7271821",
            longitude: "-79.3417915",
          }}
          images={[
            "https://storage.googleapis.com/archatech/Archatech/apple-icon.png",
            "https://storage.googleapis.com/archatech/Archatech/toronto-website.png",
            "https://storage.googleapis.com/archatech/Archatech/web-developers-toronto.png",
          ]}
          sameAs={[
            "https://twitter.com/AtlasAgencyCA",
            "https://www.facebook.com/atlasagency.ca",
            "https://medium.com/@atlasagency",
            "https://www.youtube.com/channel/UCoFe-_8cQ5HT6o_eeF32tKA",
          ]}
          openingHours={[
            {
              opens: "08:00",
              closes: "17:00",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
              ],
              validFrom: "2019-12-23",
              validThrough: "2024-04-02",
            },
            {
              opens: "12:00",
              closes: "4:00",
              dayOfWeek: "Saturday",
              validFrom: "2019-12-23",
              validThrough: "2024-04-02",
            },
          ]}
        />
        <Header
          color="transparent"
          brand="Atlas Agency"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 60,
            color: "dark",
          }}
        />
        <Parallax
          filter
          className={classes.parallax}
          responsive
          image="/contact-us.jpg"
        />

        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginTop: "-280px" }}
        >
          <div className={classes.contactContent}>
            <div className={classes.container}>
              <h1 className={classes.title}>Contact Us</h1>
              <Grid container spacing={2} direction="row">
                <Grid item md={6} sm={6} lg={6} xs={10}>
                  <p>
                    We are available to help you launch your next website.
                    Contact us to discuss your project in greater detail. We
                    {"'"}ll get in touch with you as soon as possible.
                    <br />
                    <br />
                  </p>
                </Grid>
                <Grid item md={6} sm={6} lg={8} xs={12}>
                <p>
                <Button
                        style={{ backgroundColor: "#1f60a6", color: "white", }}
                        variant="contained"
                        label="Submit"
                        type="submit"
                        href="tel:647-371-0263"
                      >
                        
                        Call Us 
                      </Button> Or Book A 
                      <CustomButton url="https://calendly.com/atlasagency/15min" />
                    
                    </p> 
                </Grid>
                <Grid item md={4} xs={12} sm={6} lg={12} >
                <iframe className={classes.Maps}
       
                    frameborder="0" 
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJzTkUtxjN1IkRDNfweMYwxXE&key=AIzaSyDh0cT_uufKW_zVoXuPZWmuHoeGm25uD14" 
                    allowfullscreen>
                  </iframe>     
                  
                </Grid>
                
              </Grid>
              
            </div>
            
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default () => {
  const classes = useStyles()
  return <ContactForm classes={classes} />
}
